





































































































































.form-textarea {
  .el-textarea {
    .el-textarea__inner {
      min-height: 13rem !important;
      resize: none;
    }
  }
}
.activeSetbtn {
  display: flex;
  justify-content: center;
  padding-bottom: 0.75rem;
}
